import "../App.css";

function HomePage() {
  return (
    <div >
    </div>
  );
}

export default HomePage;
